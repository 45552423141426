import {
  Distributor,
  getDistributorApiInfo,
} from "src/api/distributor.service";
import { FbAuth } from "src/firebase";
import { create } from "zustand";

interface authUser {
  user?: Distributor;
  isLoading: boolean;
  setUser: () => void;
}

export const setUser = create<authUser>((set) => ({
  user: undefined,
  isLoading: false,
  setUser: async () => {
    set((state) => ({ ...state, isLoading: true }));

    FbAuth.onAuthStateChanged(
      async (user) => {
        if (user) {
          try {
            const distributUser = await getDistributorApiInfo().findById(
              `${user.uid}`
            );
            set((state) => ({ ...state, user: distributUser }));
          } catch (error) {
            console.error("Error fetching user:", error);
          }
        } else {
          set((state) => ({ ...state, user: undefined }));
        }

        set((state) => ({ ...state, isLoading: false }));
      },
      (error) => {
        console.error("Auth Error:", error);
        set((state) => ({ ...state, isLoading: false }));
      }
    );
  },
}));

// export const setUser = create<authUser>((set) => ({
//   user: undefined,
//   isLoading: false,
//   setUser: async () => {
//     set({
//       isLoading: true,
//     });
//     FbAuth.onAuthStateChanged(
//       async (user) => {
//         if (user) {
//           const distributUser = await getDistributorApiInfo().findById(
//             `${user.uid}`
//           );
//           set({
//             user: distributUser,
//           });
//         }
//         set({
//           isLoading: false,
//         });
//       },
//       (e) => {
//         console.log("Error, : ", JSON.stringify(e));
//         set({
//           isLoading: false,
//         });
//       }
//     );
//   },
// }));
